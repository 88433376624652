import styled, { css, up } from "@xstyled/styled-components";
import { useMedia } from "react-use";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useStripeCheckoutLink } from "../JobsList/JobSlots/useStripeCheckoutLink";

import TopLogos from "./assets/top-logos.svg";
import BottomLogos from "./assets/bottom-logos.svg";
import MobileLogos from "./assets/mobile-logos.svg";
import { ResponsiveButtonContainer } from "./components/ResponsiveButtonContainer";

import { Button, Heading, List, Spacing, Text, theme } from "@otta/design";
import { palette, pxToEm, pxToRem } from "@otta/design-tokens";
import {
  CatalogueProductId,
  Currency,
  CurrentUserDocument,
  ProductQuoteDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { formatCurrency } from "@hire/util/currencies";
import { Link } from "@hire/components/links";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";
import { LogoHeader } from "@hire/components/layout/QuizLayout";

const PageContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 4rem);
  flex-direction: column;
  padding-bottom: 212;

  ${up(
    "desktop",
    css`
      flex-direction: row;
      padding-bottom: unset;
    `
  )}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  background-color: white;
`;

const PaddedContent = styled.div`
  padding: xl lg 0 lg;

  ${up(
    "desktop",
    css`
      padding: 0;
    `
  )}
`;

const PricingInformationHeadingContainer = styled.div`
  padding: xl lg;
  width: 100vw;

  ${up(
    "desktop",
    css`
   background-color: transparent;
   padding: 80 0 40 0};
   width: unset;
  `
  )}
`;

const StyledPricingHeading = styled.div`
  align-self: center;
  ${up(
    "desktop",
    css`
      max-width: ${pxToRem(517)};
    `
  )}
`;

const ContinueButton = styled(Button)`
  text-decoration: none;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: baseline;
  gap: sm;
`;

const MobileHeadingCard = styled.div`
  background-color: beige-200;
  background-image: url(${MobileLogos});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: ${pxToRem(212)};
  min-width: ${pxToRem(343)};
  padding: 0 41;
  justify-content: center;
  align-items: center;
`;

const PaddedContentSpacer = styled.div`
  ${up(
    "desktop",
    css`
      max-width: ${pxToRem(400)};
    `
  )}
`;

function DesktopPricingCover() {
  return (
    <Column
      style={{
        backgroundColor: palette.beige.shade200,
        justifyContent: "space-between",
      }}
    >
      <img src={TopLogos} alt="Top company logos" />
      <StyledPricingHeading>
        <Spacing>
          <Heading align="center" size={5}>
            You&apos;re in good company
          </Heading>
          <Text align="center" size={4}>
            Here are some of the companies hiring on Welcome to the Jungle
            today.
          </Text>
        </Spacing>
      </StyledPricingHeading>
      <img src={BottomLogos} alt="Bottom company logos" />
    </Column>
  );
}

function MobilePricingCover() {
  return (
    <MobileHeadingCard>
      <StyledPricingHeading>
        <Spacing>
          <Heading align="center" size={1}>
            You&apos;re in good company
          </Heading>
          <Text align="center">
            Here are some of the companies hiring on Welcome to the Jungle
            today.
          </Text>
        </Spacing>
      </StyledPricingHeading>
    </MobileHeadingCard>
  );
}

function PricingInformation({ isDesktop }: { isDesktop: boolean }) {
  const { data } = useQuery(CurrentUserDocument);
  const { callback, loading } = useStripeCheckoutLink("quiz/success");
  const { variant } = useExperiment(Experiment.CandidateCount);
  const navigate = useNavigate();
  const loggedIn = !!data?.me;
  const loggedInAsCandidate = data?.me?.__typename === "CurrentCandidate";

  const { data: productQuoteData, loading: productQuoteLoading } = useQuery(
    ProductQuoteDocument,
    {
      variables: {
        quantity: 1,
        productId: CatalogueProductId.JobSlotMonthly,
      },
    }
  );

  function getValueForCore() {
    return productQuoteData?.catalogue?.product?.quote
      ? formatCurrency(
          productQuoteData.catalogue.product.quote.netTotal,
          productQuoteData.catalogue.product.quote.currency
        )
      : formatCurrency("199", Currency.Gbp);
  }

  const formattedCorePriceValue = getValueForCore();

  useEffect(() => {
    if (loggedInAsCandidate) {
      navigate("/candidate-account");
    }
  }, [loggedInAsCandidate, navigate]);

  if (productQuoteLoading) {
    return <Loading />;
  }

  return (
    <Column style={{ width: "100%", alignItems: "center" }}>
      <div style={{ maxWidth: pxToRem(400) }}>
        <PricingInformationHeadingContainer>
          <Heading style={{ maxWidth: pxToRem(400) }} size={isDesktop ? 3 : 2}>
            For {formattedCorePriceValue} per month, you get:
          </Heading>
        </PricingInformationHeadingContainer>
        <PaddedContent>
          <PaddedContentSpacer>
            <Spacing size={isDesktop ? 5 : 2}>
              <Spacing>
                <List type="positive" style={{ maxWidth: "343px" }}>
                  <li>
                    <Text inline bold>
                      1 job slot.
                    </Text>{" "}
                    Buy more or cancel anytime.
                  </li>
                  <li>
                    Room to stand out with a{" "}
                    <Text inline bold>
                      company profile created by us.
                    </Text>
                  </li>
                  <li>
                    The{" "}
                    <Text inline bold>
                      right candidates
                    </Text>{" "}
                    viewing your job with our AI-powered matching algorithm.
                  </li>
                  <li>
                    Unlimited{" "}
                    <Text inline bold>
                      users.
                    </Text>
                  </li>
                </List>
              </Spacing>
              <ResponsiveButtonContainer breakpoint={theme.screens.desktop}>
                <Spacing size={-1.5}>
                  {variant === "variant" && (
                    <ContinueButton
                      data-analytics-id="see-how-my-job-would-perform"
                      to="/quiz/job-performance"
                      level="secondary"
                      as={Link}
                    >
                      See how my job would perform
                    </ContinueButton>
                  )}
                  <ContinueButton
                    data-analytics-id="continue"
                    onClick={() => {
                      if (!loggedIn) {
                        navigate("/quiz/company");
                      } else {
                        callback();
                      }
                    }}
                    level="primary"
                  >
                    {loading ? "Loading..." : "Continue"}
                  </ContinueButton>
                  <Footer>
                    <Text bold>No commitment.</Text>
                    <Text size={-1}>Pay monthly, cancel online anytime.</Text>
                  </Footer>
                </Spacing>
              </ResponsiveButtonContainer>
            </Spacing>
          </PaddedContentSpacer>
        </PaddedContent>
      </div>
    </Column>
  );
}

export function PricingDetails() {
  const isDesktop = useMedia(`(min-width: ${pxToEm(theme.screens.desktop)})`);
  return (
    <Main>
      <LogoHeader />
      <PageContainer>
        {isDesktop && <DesktopPricingCover />}
        {!isDesktop && <MobilePricingCover />}
        <PricingInformation isDesktop={isDesktop} />
      </PageContainer>
    </Main>
  );
}
