import styled from "@xstyled/styled-components";

import { Icon, OttaIconsId } from "@otta/icons";

/**
 * We're using old school margins rather than flex gaps here
 * so that we can tolerate empty list elements
 */
export const IconList = styled.ul`
  flex-direction: column;
  display: flex;
  &:last-child {
    margin-bottom: -0.75rem;
  }
`;

const StyledLi = styled.li`
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: flex-end;
  display: flex;
  gap: 0.5rem;
`;

const BoldIcon = styled(Icon)<{ visible: boolean }>`
  visibility: ${p => (p.visible ? "visible" : "hidden")};
  margin-top: 0.1rem;
  font-size: 1.2rem;
  font-weight: 600;
`;

const Content = styled.div`
  &:not(:empty) {
    margin-bottom: 0.75rem;
  }
  &:empty + i {
    display: none;
  }
`;

export function IconListItem({
  icon,
  children,
}: React.PropsWithChildren<{ icon?: OttaIconsId }>): React.ReactElement | null {
  return (
    <StyledLi>
      <Content>{children}</Content>
      <BoldIcon icon={icon ?? "circle-info"} visible={!!icon} />
    </StyledLi>
  );
}
