import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";

import { Button, ErrorText, TextareaField } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  DeletePipelineItemNoteDocument,
  UpsertPipelineItemNoteDocument,
} from "@hire/schema";

const NoteInput = styled(TextareaField)`
  min-height: ${pxToRem(300)};
  padding: 19;
  border-bottom: none;
  border-radius: ${pxToRem(8)} ${pxToRem(8)} 0 0;
  resize: vertical;
`;

const NoteActionBar = styled.div`
  display: flex;
  justify-content: center;
  background: ${palette.beige.shade200};
  padding: lg;
  border: 1px solid ${palette.grayscale.shade400};
  border-top: none;
  border-radius: 0 0 8 8;
  gap: lg;
`;

const ErrorContainer = styled.div`
  padding: xs 19;
  border-right: 1px solid ${palette.grayscale.shade400};
  border-left: 1px solid ${palette.grayscale.shade400};
  background: ${palette.beige.shade200};
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
`;

const validationSchema = Yup.object().shape({
  content: Yup.string().max(10000, "Must be 10,000 characters or less"),
});

const fragment = gql`
  fragment CandidatePipelineItemNote on CandidatePipelineItem {
    candidatePipelineItemNote {
      id
      content
      updatedAt
      lastUpdatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export function EditNote({
  itemId,
  jobId,
  noteId,
  content,
  onComplete,
}: {
  itemId: string;
  jobId: string;
  noteId?: string;
  content?: string;
  onComplete: () => void;
}) {
  const [deleteNote] = useMutation(DeletePipelineItemNoteDocument, {
    update(cache, result) {
      if (result.data?.deletePipelineItemNote?.id) {
        cache.writeFragment({
          id: cache.identify({
            __typename: "CandidatePipelineItem",
            id: itemId,
          }),
          fragment,
          data: {
            candidatePipelineItemNote: null,
          },
        });

        cache.evict({
          id: cache.identify({
            __typename: "CandidatePipelineItemNote",
            id: result.data.deletePipelineItemNote.id,
          }),
        });
      }
    },
    onCompleted: onComplete,
  });

  const [saveNote] = useMutation(UpsertPipelineItemNoteDocument, {
    update(cache, result) {
      if (result.data?.upsertPipelineItemNote && !content) {
        cache.writeFragment({
          id: cache.identify({
            __typename: "CandidatePipelineItem",
            id: itemId,
          }),
          fragment,
          data: {
            candidatePipelineItemNote: result.data.upsertPipelineItemNote,
          },
        });
      }
    },
    onCompleted: onComplete,
  });

  const form = useFormik({
    initialValues: {
      content: content ?? "",
    },
    validationSchema,
    onSubmit: async values => {
      if (!values.content && noteId) {
        return deleteNote({
          variables: {
            itemId,
            jobId,
            noteId,
          },
        });
      }

      if (values.content) {
        return saveNote({
          variables: {
            itemId,
            jobId,
            content: values.content,
          },
        });
      }
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <NoteInput
        name="sharedNoteInput"
        placeholder="Create a shared note that can be viewed and updated by all hiring managers."
        value={form.values.content}
        onChange={form.handleChange("content")}
        onBlur={form.handleBlur("content")}
        disabled={form.isSubmitting}
      />
      {form.touched.content && form.errors.content && (
        <ErrorContainer>
          <ErrorText>{form.errors.content}</ErrorText>
        </ErrorContainer>
      )}
      <NoteActionBar>
        {form.submitCount === 0 && (
          <Button
            type="button"
            level="secondary"
            onClick={onComplete}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
        )}
        <StyledButton
          type="submit"
          level="primary"
          disabled={form.isSubmitting}
        >
          {form.isSubmitting ? "Saving..." : "Save"}
        </StyledButton>
      </NoteActionBar>
    </form>
  );
}
