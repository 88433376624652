import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const Accordion = styled.div`
  height: ${modularScale(2)};
  width: 100%;
  background-color: gray-200;
  border-radius: 0 0 4 4;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: gray-400;
  }
`;

const IconWrapper = styled.div`
  background-color: white;
  height: ${modularScale(4)};
  width: ${modularScale(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  top: -${modularScale()};
`;

interface CardAccordionProps {
  expanded: boolean;
}

export function CardAccordion({
  expanded,
}: CardAccordionProps): React.ReactElement {
  return (
    <Accordion>
      <IconWrapper>
        <Icon icon={expanded ? "chevron-up" : "chevron-down"} />
      </IconWrapper>
    </Accordion>
  );
}
