import { Link as RouterLink, To } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";
import { isExternalUrl } from "@otta/shared-components";

const StyledA = styled.a<{
  $underline?: boolean;
  size?: number;
}>`
  color: black;
  font-weight: 600;
  cursor: pointer;
  font-size: ${({ size }) => (size ? modularScale(size) : undefined)};
  text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};
`;

export interface LinkProps {
  to: To;
  state?: unknown;
  size?: number;
  style?: Record<string, string>;
  underline?: boolean;
  newTab?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
}

export function Link({
  to,
  state,
  children,
  newTab: external,
  underline = true,
  size,
  ...props
}: LinkProps): React.ReactElement {
  const commonProps = {
    ...props,
    $underline: underline,
    target: external ? "_blank" : undefined,
    size,
  };

  if (typeof to === "string" && isExternalUrl(to)) {
    return (
      <StyledA
        {...commonProps}
        href={to}
        rel={external ? "noreferrer noopener" : undefined}
      >
        {children}
      </StyledA>
    );
  }

  return (
    <StyledA {...commonProps} as={RouterLink} to={to} state={state}>
      {children}
    </StyledA>
  );
}
