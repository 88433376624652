import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";

export const Section = styled.section`
  background-color: white;
  padding: 1rem;
`;

export const SectionHeader = styled(Text).attrs(p => ({ as: "h2", ...p }))`
  background: ${palette.grayscale.shade200};
  margin: -1.5rem -1.5rem 1rem -1.5rem;
  font-size: ${modularScale(2)};
  padding: 0.5rem 1rem;
  font-weight: 600;
`;
