import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { DropzoneRootProps, FileRejection, useDropzone } from "react-dropzone";
import styled from "@xstyled/styled-components";

import { DropzoneInner } from "./DropzoneInner";
import { UploadedFile } from "./types";

import { handleMutationError } from "@hire/errors";
import { ImageCategory, UploadImageDocument } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Button, ErrorText, Spacing } from "@otta/design";

export const UPLOADED_IMAGE_BASE_URL =
  "https://static.otta.com/uploads/images/";

const BrowseButtonContainer = styled.div`
  margin: 0 auto;
`;

const DropzoneContainer = styled.div<DropzoneRootProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: lg;
  border-radius: 4;
  border: 1px dashed
    ${props => {
      if (props.isDragAccept) {
        return palette.extended.green.shade500;
      }
      if (props.isDragReject) {
        return palette.extended.red.shade500;
      }
      if (props.isDragActive) {
        return palette.extended.blue.shade500;
      }
      return palette.grayscale.shade400;
    }};
  outline: none;
  transition: default;
`;

interface ImageUploadFieldProps {
  value: UploadedFile[];
  onChange: (files: UploadedFile[]) => void;
  category: ImageCategory;
  fileCountMax: number;
  nounSingular: string;
  nounPlural?: string;
  fileSizeMaxKb?: number;
  error?: string | string[];
  id?: string;
}

export function ImageUploadField({
  value,
  fileCountMax,
  nounSingular,
  nounPlural,
  error,
  fileSizeMaxKb = 5000,
  onChange,
  category,
  id,
}: ImageUploadFieldProps): React.ReactElement {
  const [upload, { loading: uploading }] = useMutation(UploadImageDocument, {
    onError: handleMutationError,
  });
  const [errors, setErrors] = useState<string[]>(
    error ? (Array.isArray(error) ? error : [error]) : []
  );

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = [...value.slice(0, index), ...value.slice(index + 1)];
      onChange(newFiles);
    },
    [onChange, value]
  );

  const uploadFile = useCallback(
    async (file: File) => {
      const { data } = await upload({
        variables: { file, category },
      });

      if (!data?.uploadImage?.path) {
        throw new Error("No image returned after upload");
      }

      return { path: data.uploadImage.path };
    },
    [category, upload]
  );

  const processRejections = useCallback(
    (fileRejections: FileRejection[]) => {
      setErrors([]);

      for (const rejection of fileRejections) {
        for (const error of rejection.errors) {
          if (error.code === "file-too-large") {
            setErrors(errors => [
              ...errors,
              `The image "${
                rejection.file.name
              }" is too big. The maximum accepted is ${
                fileSizeMaxKb / 1000
              }MB.`,
            ]);
          }
          if (error.code === "file-invalid-type") {
            setErrors(errors => [
              ...errors,
              `File "${rejection.file.name}" is not an image.`,
            ]);
          }
        }
      }
    },
    [fileSizeMaxKb]
  );

  const processFiles = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      processRejections(fileRejections);

      const allowedNewFiles = fileCountMax - value.length;
      if (allowedNewFiles === 0) {
        return;
      }

      const newFileUploads = await Promise.all(
        acceptedFiles
          .slice(0, allowedNewFiles)
          .map(file => uploadFile(file).catch(() => null))
      );
      const newFiles: UploadedFile[] = newFileUploads.filter(
        (f): f is UploadedFile => Boolean(f)
      );

      onChange([...value, ...newFiles]);
    },
    [fileCountMax, onChange, uploadFile, value, processRejections]
  );

  const dropzone = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    noClick: true,
    maxSize: fileSizeMaxKb * 1024,
    onDrop: processFiles,
  });

  return (
    <Spacing>
      <DropzoneContainer
        data-testid={`image-upload-field-dropzone${id ? `-${id}` : ""}`}
        {...dropzone.getRootProps({
          isDragActive: dropzone.isDragActive,
          isDragAccept: dropzone.isDragAccept,
          isDragReject: dropzone.isDragReject,
        })}
      >
        <input
          {...dropzone.getInputProps({ id })}
          data-testid={`image-upload-field-input${id ? `-${id}` : ""}`}
        />
        <DropzoneInner
          uploading={uploading}
          nounPlural={nounPlural ?? `${nounSingular}s`}
          nounSingular={nounSingular}
          images={value}
          maxImages={fileCountMax}
          error={!!error && errors.length > 0}
          handleRemove={removeFile}
        />
        {value.length < fileCountMax && (
          <BrowseButtonContainer>
            <Button onClick={dropzone.open} level="secondary" type="button">
              Browse&hellip;
            </Button>
          </BrowseButtonContainer>
        )}
      </DropzoneContainer>

      {errors.map((error, index) => (
        <p key={`error-${index}`}>
          <ErrorText>{error}</ErrorText>
        </p>
      ))}
    </Spacing>
  );
}
