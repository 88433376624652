import { Heading, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";

export const ATSConnected = () => {
  return (
    <Spacing>
      <Heading size={1}>ATS connected</Heading>
      <Text>
        When new jobs are uploaded we will automatically add them to Welcome to
        the Jungle.{" "}
        <Link underline to="settings/ats">
          Check your ATS integration settings
        </Link>
      </Text>
    </Spacing>
  );
};
