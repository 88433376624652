import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button, Text } from "@otta/design";
import { Link } from "@hire/components/links";

const BannerContainer = styled.div`
  background: ${palette.extended.yellow.shade100};
  z-index: 1;
  height: fit-content;
  padding: 20;
  display: flex;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20;
`;

const SalaryButton = styled(Button)`
  padding: 5 15;
`;

const CloseIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export function SalaryNudgeBanner({
  closeSalaryBanner,
}: {
  closeSalaryBanner: () => void;
}) {
  const navigate = useNavigate();

  const redirectAndScrollToCompensation = async () => {
    const element = document.getElementById(
      "compensation-job-edit-form-section"
    );

    navigate("./edit");

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <BannerContainer data-testid="banner-container">
      <LeftWrapper>
        <Text>
          <strong>Adding a salary can increase applications by 40%.</strong> Not
          sure what to pay?{" "}
          <Link
            to={`${import.meta.env.VITE_SEARCH_APP_HOST}/salaries`}
            newTab
            underline={false}
          >
            <u>Check out our salary benchmarks</u>{" "}
            <Icon icon="external" size={-1} />
          </Link>
        </Text>
        <SalaryButton
          level="primary"
          size="small"
          onClick={() => redirectAndScrollToCompensation()}
        >
          Add salary
        </SalaryButton>
      </LeftWrapper>
      <CloseIcon
        data-testid="close-banner"
        icon="close"
        onClick={closeSalaryBanner}
      />
    </BannerContainer>
  );
}
