import { useEffect, useMemo } from "react";
import styled, { css, keyframes, up } from "@xstyled/styled-components";
import { useMedia } from "react-use";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import CandidateCards from "../../assets/candidate-cards.svg";

import { useJobPerformanceParams } from "./useJobPerformanceQuery";
import AnimatedCounter from "./AnimatedCounter";

import { RoundedTag } from "@hire/components/tags/RoundedTag";
import { Text, Card, Spacing, Heading, theme } from "@otta/design";
import { modularScale, palette, pxToEm, pxToRem } from "@otta/design-tokens";
import {
  PotentialCandidatesDocument,
  QuizFieldValuesDocument,
} from "@hire/schema";
import { locationToLocationOption } from "@hire/util/locations";
import { yearsToCategoryLabels } from "@hire/components/sliders/ExperienceRangeSlider";
import { Loading } from "@otta/shared-components";
import { pushAnalyticsEvent } from "@otta/analytics";

const Column = styled.div`
  display: flex;
  background-color: beige-200;
  justify-content: center;
  ${up(
    "desktop",
    css`
      justify-content: initial;
      width: ${pxToRem(950)};
      padding-top: 6rem;
    `
  )}
`;

const StyledPricingHeading = styled(Card)`
  background-color: beige-200;
  padding: xl xxl xl xxl;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${up(
    "desktop",
    css`
      text-align: left;
      padding: 0 4xl xl 4xl;
    `
  )}
`;

const CandidateCardContainer = styled.div`
  width: ${pxToRem(220)};
  height: ${pxToRem(180)};
  margin-bottom: lg;
  position: relative;
  background-image: url(${CandidateCards});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: -4rem;
  ${up(
    "desktop",
    css`
      margin-bottom: 40;
      margin-left: -6rem;
    `
  )}
`;

const fadeInAnimation = keyframes`
  0% { opacity: 0; transform: translate3d(0, 16px, 0)}
  100% { opacity: 1; transform: translate3d(0, 0px, 0)}
`;

const StyledRoundedTag = styled(RoundedTag).attrs({ bold: true })<{
  top: number;
  right: number;
}>`
  margin: 0 xs;
  padding: sm md;
  border-radius: 4rem;
  position: absolute;
  top: ${({ top }) => pxToRem(top)};
  left: ${({ right }) => pxToRem(right)};
  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    transform: translate3d(0, -32px, 0);
    animation: ${fadeInAnimation} 0.6s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
`;

const TagText = styled(Text)`
  font-weight: 600;
  font-size: ${modularScale(-2)};
  max-width: min(30vw, ${pxToRem(180)});
  overflow: hidden;
  text-overflow: ellipsis;
  ${up(
    "desktop",
    css`
      font-size: ${modularScale(-1)};
    `
  )}
`;

const CandidatesHeading = styled(Heading)`
  margin-right: auto;
  line-height: 1.3;
  ${up(
    "desktop",
    css`
      line-height: 1.3;
    `
  )}
`;

interface AnimatedLabels {
  locations?: string | undefined;
  functions?: string | undefined;
  experiences?: string | undefined;
}

export function CandidateMatchCount() {
  const { data: options } = useQuery(QuizFieldValuesDocument);
  const params = useJobPerformanceParams();
  const navigate = useNavigate();

  const isDesktop = useMedia(`(min-width: ${pxToEm(theme.screens.desktop)})`);

  const { data: countData, loading: countLoading } = useQuery(
    PotentialCandidatesDocument,
    {
      variables: {
        locationIds: params?.locations ?? [],
        languageIds: params?.languages ?? [],
        minExp: params?.experiences?.[0] ?? 0,
        maxExp: params?.experiences?.[1] ?? 0,
        subFunctionId: params?.function as string,
      },
      skip: !params,
    }
  );

  const labels = useMemo<AnimatedLabels | null>(() => {
    if (!params) {
      return null;
    }

    const locationToText = params.locations.map(val => {
      return locationToLocationOption(val)?.label;
    });
    const filteredFunction = options?.jobFunctions
      ?.map(jobFunction => {
        return jobFunction.subFunctions.find(subFunc => {
          return subFunc.id === params.function;
        });
      })
      .find(val => val !== undefined);

    return {
      experiences: yearsToCategoryLabels([
        params?.experiences[0],
        params?.experiences[1],
      ]).join("-"),
      locations: locationToText.join(", ") ?? "",
      functions: filteredFunction?.value ?? "",
    };
  }, [options?.jobFunctions, params]);

  useEffect(() => {
    if (typeof countData?.potentialCandidates === "number" && labels) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Viewed Candidate Count",
        candidateCount: countData?.potentialCandidates,
        ...labels,
      });
    }
  }, [countData?.potentialCandidates, labels]);

  useEffect(() => {
    if (!params) {
      navigate("/quiz/job-performance");
    }
  }, [navigate, params]);

  return (
    <Column>
      {countLoading ? (
        <Loading />
      ) : (
        <StyledPricingHeading>
          <CandidateCardContainer>
            {labels?.locations && (
              <StyledRoundedTag
                top={0}
                right={110}
                icon="location"
                color={palette.brand.pink}
                content={<TagText>{labels?.locations ?? ""}</TagText>}
              />
            )}
            {labels?.functions && (
              <StyledRoundedTag
                top={38}
                right={135}
                icon="industry"
                color={palette.brand.orange}
                content={<TagText>{labels?.functions ?? ""}</TagText>}
              />
            )}
            {labels?.experiences && (
              <StyledRoundedTag
                top={76}
                right={110}
                icon="steps"
                color={palette.brand.green}
                content={<TagText>{labels?.experiences ?? ""}</TagText>}
              />
            )}
          </CandidateCardContainer>
          <Spacing size={2}>
            <CandidatesHeading size={isDesktop ? 5 : 3}>
              <AnimatedCounter countTo={countData?.potentialCandidates ?? 0} />{" "}
              candidates
              <br /> match your job
            </CandidatesHeading>
            {isDesktop && (
              <Text size={3}>
                Only the right candidates are viewing your job with our
                AI-powered matching algorithm.
              </Text>
            )}
          </Spacing>
        </StyledPricingHeading>
      )}
    </Column>
  );
}
