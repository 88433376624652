import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  border-radius: 8;
  padding: lg;
  width: ${pxToRem(400)};
  background-color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: ${pxToRem(32)};
  p {
    margin-top: 16px;
  }
`;

export function MaintenancePage(): React.ReactElement {
  return (
    <Wrapper>
      <InnerWrapper>
        <Icon icon="settings" size={4} />
        <Text data-testid="maintenance-text" align="center" size={1}>
          We're upgrading this feature. We're working hard to get this feature
          back online quickly.
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
}
