import { Link } from "react-router-dom";
import styled from "styled-components";

import { useJobEditFormikContext } from "../useHandlers";
import { IOption } from "../types";

import {
  Fieldset,
  FieldWrapper,
  SelectField,
  Text,
  WttjSymbol,
} from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { JobFragment } from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";

const CSMLink = styled(Link)`
  color: ${palette.grayscale.shade600};
`;

const SelectFieldContainer = styled.div`
  max-width: ${pxToRem(400)};
`;

const TagContainer = styled.div`
  background: ${palette.brand.yellow};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 5px 10px;
`;

const SymbolContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function Team({
  teamOptions,
}: {
  teamOptions: IOption[] | [];
  companyJob?: JobFragment | null;
}) {
  const hasTeams = teamOptions.length > 0;

  const form = useJobEditFormikContext();

  const csmButtonLink =
    "https://employers.welcometothejungle.com/meetings/abby-scarborough/optimise-your-profile";

  const handleChange = ({ value, label }: IOption) => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter selected EB team",
      value: label,
    });

    form.setValues({
      ...form.values,
      companyTeamId: value,
    });
  };

  const advice = (
    <>
      If you want to add, remove or change teams,{" "}
      <CSMLink target="_blank" to={csmButtonLink}>
        let us know
      </CSMLink>
      .
    </>
  );

  const tag = (
    <TagContainer>
      <SymbolContainer>
        <WttjSymbol />
      </SymbolContainer>
      <Text bold size={-1}>
        Employer branding feature
      </Text>
    </TagContainer>
  );

  return (
    <Fieldset legend="Team" advice={advice} tag={tag}>
      <FieldWrapper>
        {() => (
          <SelectFieldContainer data-testid="select-team">
            <SelectField
              name="team"
              value={teamOptions.find(
                option => form.values.companyTeamId === option.value
              )}
              options={teamOptions}
              placeholder={hasTeams ? "Select a team..." : "No teams found"}
              isDisabled={!hasTeams}
              onChange={e => e && handleChange(e)}
            />
          </SelectFieldContainer>
        )}
      </FieldWrapper>
    </Fieldset>
  );
}
