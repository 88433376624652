import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { CardContainer } from "../../../components/containers/CardContainer";

import { CompanyDiversityForm } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CompanyDiversityDocument,
  CompanyInput,
  UpdateCompanyDiversityDocument,
} from "@hire/schema";
import { ReloadableError } from "@hire/components/ReloadableError";
import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";

export const DiversityInclusion = () => {
  const { data, loading, refetch } = useQuery(CompanyDiversityDocument);
  const [update] = useMutation(UpdateCompanyDiversityDocument, {
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: CompanyInput) => {
      if (!data?.currentUser?.currentCompany?.id) {
        return;
      }
      await update({
        variables: {
          id: data?.currentUser?.currentCompany?.id,
          input,
        },
      });
    },
    [data, update]
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser?.currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <>
      <Spacing>
        <Text bold size={2} align="center">
          Diversity, equity and inclusion
        </Text>
        <Text align="center">
          This information is key to overall company performance on Welcome to
          the Jungle. It ensures you attract a diverse range of candidates,
          especially from underrepresented groups.
        </Text>
      </Spacing>
      <CardContainer>
        <CompanyDiversityForm
          data={data.currentUser?.currentCompany}
          handleSave={handleSave}
        />
      </CardContainer>
    </>
  );
};
