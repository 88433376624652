import { Command as Cmd } from "cmdk";
import { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";

import { JobPage } from "./JobPage";
import { Jobs } from "./Jobs";
import { BasePage } from "./BasePage";
import { Conversations } from "./Conversations";

import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Clickable, Text } from "@otta/design";
import {
  CommandConversationsDocument,
  CommandPaletteDocument,
} from "@hire/schema";
import { hireAppUser } from "@hire/util/user";
import { Loading } from "@otta/shared-components";
import { pushAnalyticsEvent } from "@otta/analytics";

const Styles = createGlobalStyle`
  [cmdk-overlay] {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-items: center;
    z-index: 100;
    padding: 19;
  }

  [cmdk-dialog] {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem;
    max-width: ${pxToRem(800)};
    z-index: 101;
  }

  [cmdk-input] {
    display: block;
    width: 100%;
    border: none;
    padding: 0.5rem;
    border-radius: 4;
    font-size: ${modularScale()};
    font-family: inherit;
    &:focus {
      outline: none;
    }
  }

  [cmdk-list] {
    box-sizing: initial;
    display: block;
    padding: 0 0.5rem 0.5rem;
    min-height: 200px;
    height: calc(var(--cmdk-list-height) + 1rem);
    max-height: 400px;
    transition: default;
    overflow-y: auto;
  }


  [cmdk-group-heading] {
    padding-left: 0.5rem;
    font-size: ${modularScale(-1)};
    color: gray-600;
    margin: 0.5rem 0;
  }

  [cmdk-item] {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &[data-selected=true] {
      background-color: beige-200;
    }
  }

  [cmdk-empty] {
    padding: 1rem 0.5rem;
  }

  [cmdk-loading] {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 4;
  overflow: hidden;
`;

const InputContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid ${palette.grayscale.shade200};
  position: relative;
`;

export function Command({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  const [jobId, setJobId] = useState<string>();

  const [search, setSearch] = useState("");

  const { data, loading } = useQuery(CommandPaletteDocument, { skip: !open });

  const {
    data: conversationsData,
    refetch,
    loading: conversationsLoading,
  } = useQuery(CommandConversationsDocument, {
    variables: {
      limit: 20,
      offset: 0,
    },
    skip: !open,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(true);

        pushAnalyticsEvent({
          eventName: "Company Recruiter Opened Command Palette",
          method: "keyboard",
        });
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  const company = hireAppUser(data?.me)?.currentCompany;

  const jobs = company?.listJobs ?? [];

  const slug = company?.urlSafeName
    ? encodeURIComponent(company.urlSafeName.toLowerCase())
    : undefined;

  const job = jobs.find(j => j.id === jobId);

  const conversations =
    hireAppUser(conversationsData?.me)?.companyConversations ?? [];

  useEffect(() => {
    if (open) {
      const timer = window.setTimeout(() => {
        refetch({
          limit: 20,
          offset: 0,
          name: search.length ? search : undefined,
        });
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [open, refetch, search]);

  return (
    <>
      <Styles />
      <Clickable
        onClick={() => {
          setOpen(true);
          pushAnalyticsEvent({
            eventName: "Company Recruiter Opened Command Palette",
            method: "button",
          });
        }}
      >
        {children}
      </Clickable>
      <Cmd.Dialog
        open={open}
        onOpenChange={setOpen}
        loop
        onKeyDown={e => {
          if (
            job &&
            (e.key === "Escape" || (e.key === "Backspace" && !search))
          ) {
            e.preventDefault();
            setJobId(undefined);
            setSearch("");
          }
        }}
      >
        <Card>
          <InputContainer>
            <Cmd.Input
              value={search}
              onValueChange={setSearch}
              placeholder="Search for jobs, candidates or pages"
            />
            {(loading || conversationsLoading) && (
              <Cmd.Loading>
                <Loading />
              </Cmd.Loading>
            )}
          </InputContainer>
          <Cmd.List>
            <Cmd.Empty>
              <Text>No results found.</Text>
            </Cmd.Empty>
            {!job && (
              <>
                <BasePage
                  slug={slug}
                  onSelect={() => {
                    setSearch("");
                    setOpen(false);
                  }}
                />
                {jobs.length > 0 && (
                  <Jobs
                    jobs={jobs}
                    onSelect={job => {
                      setJobId(job);
                      setSearch("");
                    }}
                  />
                )}
                {slug && conversations.length > 0 && (
                  <Conversations
                    slug={slug}
                    conversations={conversations}
                    onSelect={() => {
                      setSearch("");
                      setOpen(false);
                    }}
                  />
                )}
              </>
            )}
            {job && slug && (
              <JobPage
                id={job.externalId}
                slug={slug}
                title={job.title}
                access={job.hasAccess}
                onSelect={() => {
                  setJobId(undefined);
                  setSearch("");
                  setOpen(false);
                }}
              />
            )}
          </Cmd.List>
        </Card>
      </Cmd.Dialog>
    </>
  );
}
