import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { UpsellRelevantCandidatesDocument } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Card, Button, Text, Spacing } from "@otta/design";
import { useRequiredParams } from "@hire/util/routing";
import { Link } from "@hire/components/links";

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20;
  padding-top: 5;
`;

const StageColumn = styled(Card)`
  background: ${palette.brand.green};
  padding: lg sm;
  border-radius: 8;
  height: fit-content;
`;

export const ProUpsellPipelineStage = () => {
  const { jobId } = useRequiredParams(["jobId"]);
  const { data } = useQuery(UpsellRelevantCandidatesDocument, {
    variables: { id: jobId },
  });

  const numRelevantCandidates =
    data?.companyJob?.personalisedCandidates?.total ?? null;

  const message = numRelevantCandidates
    ? `Source ${numRelevantCandidates} relevant candidates by upgrading to Pro.`
    : "Source relevant candidates by upgrading to Pro.";

  return (
    <StageColumn>
      <HeadingWrapper>
        <Text bold>Talent pool</Text>
      </HeadingWrapper>
      <Spacing>
        <Text>{message}</Text>
        <Button
          as={Link}
          underline={false}
          level="primary"
          to="/book-a-call?utm_campaign=pipeline&utm_source=hire"
          newTab
          style={{ whiteSpace: "nowrap" }}
        >
          Book a demo
        </Button>
      </Spacing>
    </StageColumn>
  );
};
