import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const ListItemWrapper = styled.div`
  flex: 1;
`;

export const UpdateDeleteButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: lg;
  gap: lg;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: lg;
  padding: lg;
  border-radius: 4;
  border: 1px solid ${palette.grayscale.shade200};
`;
