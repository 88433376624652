import { Link } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

const Container = styled(Link)`
  background: ${palette.brand.white};
  padding: lg;
  border-radius: 4;
  border: 1px solid ${palette.grayscale.shade400};
  color: black;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: gray-50;
    border: 1px solid ${palette.brand.black};
  }

  ${up(
    "tablet",
    css`
      max-width: ${pxToRem(110)};
    `
  )}
`;

export const ViewAllJobs = ({ total }: { total: number }) => (
  <Container to="jobs">View {total} more jobs</Container>
);
