import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { useUp } from "@xstyled/styled-components";

import { CompanyProfileSubNav } from "../SubNavBar/CompanyProfileSubNav";
import { SettingsSubNav } from "../SubNavBar/SettingsSubNav";

import {
  Hamburger,
  HamburgerControl,
  HamburgerControlBottom,
  HamburgerControlMiddle,
  HamburgerControlTop,
  LinkListWrapper,
  Links,
  WttjLogoContainer,
  Nav,
  NavItem,
  NavText,
} from "./nav-styled-components";
import { NavBarItem } from "./NavBarItem";

import { useAuthorizations } from "@hire/providers/authorization";
import { CurrentUserDocument, Feature } from "@hire/schema";
import { hireAppUser } from "@hire/util/user";
import {
  CircleUser,
  Company,
  House,
  Industry,
  Search,
  Settings,
} from "@otta/icons";
import { Command } from "@hire/components/Command";
import { WttjLogo, WttjSymbol } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const StyledWttjSymbol = styled(WttjSymbol)`
  height: ${pxToRem(40)};
`;

const StyledWttjLogo = styled(WttjLogo)`
  height: ${pxToRem(32)};
  margin-left: ${pxToRem(16)};
`;

export const NavBar = () => {
  const { data } = useQuery(CurrentUserDocument);
  const { features } = useAuthorizations([
    Feature.Home,
    Feature.ViewJobs,
    Feature.CompanyProfile,
    Feature.Settings,
    Feature.UserProfile,
  ]);
  const [open, setOpen] = useState(false);

  const onLinkClick = () => {
    setOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (open && el) {
      disableBodyScroll(el);
      return () => {
        enableBodyScroll(el);
      };
    }
  }, [open]);

  const companySlug = hireAppUser(
    data?.me
  )?.currentCompany?.urlSafeName.toLowerCase();

  const basePath = companySlug ? encodeURIComponent(companySlug) : null;

  const isTablet = useUp("tablet");

  return (
    <Nav data-testid="nav-bar">
      <Hamburger
        onClick={() => {
          setOpen(o => !o);
        }}
      >
        <HamburgerControl>
          <HamburgerControlTop open={open} />
          <HamburgerControlMiddle open={open} />
          <HamburgerControlBottom open={open} />
        </HamburgerControl>
      </Hamburger>
      <WttjLogoContainer as={RouterLink} to="/">
        {isTablet ? <StyledWttjSymbol /> : <StyledWttjLogo />}
      </WttjLogoContainer>
      <Links ref={ref} open={open}>
        <LinkListWrapper>
          {basePath && features.get(Feature.Home)?.granted && (
            <NavBarItem
              route={basePath}
              icon={<House />}
              name="Home"
              end
              onClick={onLinkClick}
            />
          )}
          {basePath && features.get(Feature.ViewJobs)?.granted && (
            <NavBarItem
              route={`${basePath}/jobs`}
              icon={<Industry />}
              name="Jobs"
              onClick={onLinkClick}
            />
          )}
          {basePath && features.get(Feature.CompanyProfile)?.granted && (
            <NavBarItem
              route={`${basePath}/company-profile`}
              icon={<Company />}
              name="Company"
              onClick={onLinkClick}
              subnav={CompanyProfileSubNav}
            />
          )}
          {basePath && features.get(Feature.Settings)?.granted && (
            <NavBarItem
              route={`${basePath}/settings`}
              icon={<Settings />}
              name="Settings"
              onClick={onLinkClick}
              subnav={SettingsSubNav}
            />
          )}
        </LinkListWrapper>
        <LinkListWrapper>
          <Command>
            <NavItem as="div">
              <Search />
              <NavText>Search</NavText>
            </NavItem>
          </Command>
          {features.get(Feature.UserProfile)?.granted && (
            <NavBarItem
              route="user"
              name="You"
              icon={<CircleUser />}
              onClick={onLinkClick}
            />
          )}
        </LinkListWrapper>
      </Links>
    </Nav>
  );
};
