import { NavLink } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

interface TabLinkProps {
  size?: number;
}

export const TabLink = styled(NavLink)<TabLinkProps>`
  font-size: ${({ size = 0 }) => modularScale(size)};
  color: black;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 5px solid transparent;
  padding-bottom: 6;
  cursor: pointer;
  &:hover {
    border-color: gray-200;
  }
  &.active {
    border-color: yellow-500;
  }
`;
