import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";

const StyledBlob = styled.div<{ green?: boolean }>`
  max-width: fit-content;
  background-color: ${p =>
    p.green ? palette.brand.green : palette.beige.shade200};
  padding: 0.25rem 0.5rem;
  border-radius: 4;
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Blobs = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 0.5rem;
`;

export function Blob({
  className,
  children,
  bold,
  icon,
}: React.PropsWithChildren<{
  className?: string;
  icon?: OttaIconsId;
  bold?: boolean;
}>): React.ReactElement {
  return (
    <StyledBlob green={bold} className={className}>
      <Row>
        {!!icon && <Icon icon={icon} />}
        <Text bold={bold}>{children}</Text>
      </Row>
    </StyledBlob>
  );
}
