import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";

const StepRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StepCounter = styled.div`
  min-width: ${pxToRem(28)};
  height: ${pxToRem(28)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: lg;
  padding: 4px;
  background-color: yellow-100;
  color: gray-600;
  user-select: none;
  font-weight: 600;
`;

const StepContent = styled.div`
  flex-grow: 1;
  padding-top: 4px;
  text-align: left;
`;

export function Step({
  idx,
  children,
}: {
  idx: number;
  children: React.ReactNode;
}) {
  return (
    <StepRow>
      <StepCounter>{idx}</StepCounter>
      <StepContent data-testid="step-content">{children}</StepContent>
    </StepRow>
  );
}
