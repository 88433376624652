import { Fragment } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { Spacing, Text } from "@otta/design";

const CopyMatrix = styled.div`
  justify-content: flex-start;
  flex-grow: 1;
  text-align: left;
`;

const CopyValuesContainer = styled.div`
  display: grid;
  row-gap: md;
  grid-template-columns: 1fr;
  ${up(
    "tablet",
    css`
      row-gap: 6;
      grid-template-columns: auto 1fr;
    `
  )}
`;

const CopyLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: lg;

  ${up(
    "tablet",
    css`
      justify-content: flex-end;
      text-align: right;
    `
  )}
`;

export const CopyPasteMatrix = ({
  helpText,
  rows,
}: {
  helpText: string;
  rows: { label: string; element: React.ReactNode }[];
}): React.ReactElement => (
  <CopyMatrix>
    <Spacing size={-1}>
      <Text>{helpText}</Text>
      <CopyValuesContainer>
        {rows.map(({ label, element }) => (
          <Fragment key={label}>
            <CopyLabel>{label}</CopyLabel>
            {element}
          </Fragment>
        ))}
      </CopyValuesContainer>
    </Spacing>
  </CopyMatrix>
);
