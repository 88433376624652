import styled from "@xstyled/styled-components";

import { SectionProps } from "../../common/types";
import { Blob } from "../../common/Blob";

import { Text } from "@otta/design";
import { Currency } from "@hire/schema";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { currencyPrefix } from "@hire/util/currencies";

function formatSalaryRange(
  currency: Currency,
  min?: number,
  max?: number
): string | null {
  if (!currency || !min) {
    return null;
  }

  const formattedMin = (min / 1000).toFixed(0);
  const formattedMax = max ? (max / 1000).toFixed(0) : null;
  const prefix = currencyPrefix(currency);

  if (!formattedMax || formattedMin === formattedMax) {
    return `${prefix}${formattedMin}k`;
  } else {
    return `${prefix}${formattedMin}-${formattedMax}k`;
  }
}

const OTEWrapper = styled.div`
  padding: 5 sm;
  background-color: green-200;
  flex-direction: column;
  border-radius: 4;
  display: flex;
  gap: 0.5rem;
`;

const SalaryTag = styled.div`
  font-size: ${modularScale(-1)};
  padding: xxs xs;
  border-radius: 4;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 1rem;
  font-weight: 600;
  float: right;
`;

const OTEDivider = styled.hr`
  border-width: ${pxToRem(1)} 0 0 0;
  border-top: ${pxToRem(1)} solid ${palette.grayscale.shade400};
`;

const BaseTag = styled(SalaryTag)`
  background: ${palette.brand.white};
  color: green-600;
`;

const OTETag = styled(SalaryTag)`
  background: ${palette.extended.green.shade600};
  color: white;
`;

function OTESalary({
  base,
  ote,
}: {
  base: string;
  ote: string;
}): React.ReactElement {
  return (
    <OTEWrapper>
      <Text bold>
        {ote} <OTETag>OTE</OTETag>
      </Text>
      <OTEDivider />
      <Text>
        {base} <BaseTag>Base</BaseTag>
      </Text>
    </OTEWrapper>
  );
}

export function JobSalary({
  fields: {
    oteRange,
    salaryRange: { minAmount, maxAmount, currency },
    hideSalary,
  },
}: SectionProps): React.ReactElement | null {
  if (hideSalary && minAmount) {
    return (
      <Blob bold icon="circle-up">
        Salary above your minimum
      </Blob>
    );
  }

  const base = formatSalaryRange(
    currency,
    minAmount ?? undefined,
    maxAmount ?? undefined
  );

  const ote = formatSalaryRange(
    currency,
    oteRange?.minAmount ?? undefined,
    oteRange?.maxAmount ?? undefined
  );

  if (ote && base) {
    return <OTESalary base={base} ote={ote} />;
  } else if (base) {
    return <Blob bold>{base}</Blob>;
  } else {
    return <Blob bold>[Salary range]</Blob>;
  }
}
