import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useState } from "react";

import { SourcingPage } from "../../enums";

import { CandidateLoading } from "./CandidateLoading";
import { CandidateSummary } from "./CandidateSummary";
import { CandidateExpanded } from "./CandidateExpanded";
import { CardAccordion } from "./CardAccordion";
import { CandidateCardActions } from "./interfaces";

import { CandidateCardJobQuery, CandidateCardDocument } from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  Clickable as DefaultClickable,
  Card as DefaultCard,
} from "@otta/design";

const Clickable = styled(DefaultClickable)`
  width: 100%;
`;

const Card = styled(DefaultCard)`
  cursor: pointer;
  padding: 0;
  border-radius: 4 4 0 0;
`;

interface CandidateCardProps extends CandidateCardActions {
  type: SourcingPage;
  candidate: {
    externalId: string;
    diversityMatch?: boolean;
  };
  job: CandidateCardJobQuery["companyJob"];
}

export function CandidateCard({
  candidate: { externalId: id },
  type,
  job,
  ...rest
}: CandidateCardProps): React.ReactElement {
  const { jobId } = useParams();

  const [expanded, setExpanded] = useState(false);

  const { data } = useQuery(CandidateCardDocument, {
    variables: {
      candidateId: id,
      jobId: jobId as string,
    },
  });

  const candidate = data?.candidate;

  const handleClick = () => {
    if (!expanded && candidate?.id) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Expanded Candidate's Public Otta Profile",
        source: "Sourcing",
        expanded: true,
        candidateUserId: candidate?.id,
      });
    }

    setExpanded(!expanded);
  };

  return (
    <Clickable onClick={handleClick}>
      <Card>
        {!candidate || !job ? (
          <CandidateLoading />
        ) : (
          <>
            <CandidateSummary candidate={candidate} job={job} {...rest} />
            {expanded && <CandidateExpanded candidate={candidate} job={job} />}
          </>
        )}
      </Card>
      <CardAccordion expanded={expanded} />
    </Clickable>
  );
}
