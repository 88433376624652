import { useState } from "react";
import styled from "@xstyled/styled-components";

import { FilterToggle } from "./components/FilterToggle";
import { ShortFilterOptions } from "./components/graph-helpers";

import { Card, Heading, HorizontalChart, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { JobCandidatePoolStatistics } from "@hire/schema";

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled(Card)`
  background-color: white;
  border: 1px solid ${palette.grayscale.shade200};
  padding: xl;

  width: 100%;
`;

export function CandidatePool({
  data,
  isProCompany,
}: {
  data?: JobCandidatePoolStatistics;
  isProCompany: boolean;
}) {
  const [currentFilter, setCurrentFilter] =
    useState<ShortFilterOptions>("genders");

  return (
    <Spacing>
      <TitleSection>
        <div>
          <Text bold size={2}>
            Candidate pool
          </Text>
          <Text>Matched candidates active in the last 12 weeks</Text>
        </div>
        {isProCompany && (
          <FilterToggle
            currentFilter={currentFilter}
            changeFilter={filter =>
              setCurrentFilter(filter === "all" ? "genders" : filter)
            }
            section="candidate-pool"
            displayAll={false}
          />
        )}
      </TitleSection>

      <Container>
        <Spacing>
          <Heading bold size={3}>
            {data?.total.toLocaleString()} candidates
          </Heading>
          {isProCompany && data && data[currentFilter] && (
            <HorizontalChart data={data[currentFilter] ?? []} />
          )}
        </Spacing>
      </Container>
    </Spacing>
  );
}
