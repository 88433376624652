import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

export const PlaceholderWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: sm;
  height: ${pxToRem(280)};
  padding: 20;
  border-radius: 4;
  border: 1px dashed ${palette.grayscale.shade400};
  background-color: ${palette.grayscale.shade50};
  text-align: center;
`;
