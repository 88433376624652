import { useContext, useId } from "react";
import styled from "@xstyled/styled-components";

import {
  HighlightableTextContextProps,
  HighlightableTextContext,
} from "./HighlightableTextProvider";

const Highlighted = styled.mark`
  background-color: yellow-500;
  border-radius: 4;
`;

interface HighlightableTextProps {
  children: string;
}

export function HighlightableText({
  children: text,
}: HighlightableTextProps): React.ReactElement {
  const { keywords } = useContext<HighlightableTextContextProps>(
    HighlightableTextContext
  );

  const id = useId();
  if (keywords.length === 0) {
    return <>{text}</>;
  }

  const escapedKeywords = keywords.map(keyword =>
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  );

  const parts = text.split(
    new RegExp(`(^|,|\\s)(${escapedKeywords.join("|")})(?=\\s|,|.|$)`, "gi")
  );

  return (
    <>
      {parts.map((part, i) =>
        keywords.includes(part.toLowerCase()) ? (
          <Highlighted key={`${id}-${i}`}>{part}</Highlighted>
        ) : (
          part
        )
      )}
    </>
  );
}
