import styled from "@xstyled/styled-components";
import { useId } from "react";

import { palette } from "@otta/design-tokens";

const Container = styled.div`
  border: 1px solid ${palette.grayscale.shade400};
  max-width: fit-content;
  border-radius: 4;
  position: relative;
  padding: 0.25rem;
  display: flex;
  gap: 0.25rem;
`;

const StyledLabel = styled.label`
  border-radius: 4;
  padding: 0.25rem 1rem;
  line-height: 1.4rem;
  cursor: pointer;
`;

const StyledInput = styled.input`
  z-index: -1;
  position: absolute;
  &:focus + label {
    border: 2px solid black;
    margin: -2px;
  }
  &:checked + label {
    background-color: yellow-500;
    font-weight: 600;
  }
`;

type Props = {
  partTime: React.InputHTMLAttributes<HTMLInputElement>;
  fullTime: React.InputHTMLAttributes<HTMLInputElement>;
};

export enum FullOrPartTime {
  FullTime = "FULL_TIME",
  PartTime = "PART_TIME",
}

export function FullTimeField(props: Props): React.ReactElement {
  const id = useId();
  return (
    <Container role="radiogroup">
      <StyledInput {...props.fullTime} id={`${id}_ft`} type="radio" />
      <StyledLabel htmlFor={`${id}_ft`}>Full time</StyledLabel>
      <StyledInput {...props.partTime} id={`${id}_pt`} type="radio" />
      <StyledLabel htmlFor={`${id}_pt`}>Part time</StyledLabel>
    </Container>
  );
}
