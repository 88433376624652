import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { TeamMemberList } from "./TeamMemberList";

import { TeamMembersDocument } from "@hire/schema";
import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { CardContainer } from "@hire/components/containers/CardContainer";
import { CopyTextField } from "@hire/components/CopyTextField";
import { ReloadableError } from "@hire/components/ReloadableError";
import { hireAppUser } from "@hire/util/user";
import { pxToRem } from "@otta/design-tokens";

const CopyInviteUrl = styled(CopyTextField)`
  width: 0;
  flex-grow: 1;
`;

export const Team = () => {
  const { data, loading, refetch } = useQuery(TeamMembersDocument);

  const currentUser = hireAppUser(data?.me);

  if (loading) {
    return <Loading />;
  }

  if (!currentUser || !currentUser.currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  const slug = encodeURIComponent(
    currentUser.currentCompany.urlSafeName.toLowerCase()
  );

  return (
    <>
      <Spacing>
        <Text bold size={2}>
          Team members
          {currentUser.currentCompany?.companyRecruiters &&
            ` (${currentUser.currentCompany?.companyRecruiters.length})`}
        </Text>

        <Text>
          Invite team members to sign up by sending them the link below:
        </Text>
        <div style={{ maxWidth: pxToRem(500), margin: "0 auto" }}>
          <CopyInviteUrl
            level="secondary"
            value={`https://hire.welcometothejungle.com/signup/${slug}`}
          />
        </div>
      </Spacing>
      <CardContainer>
        {loading ? (
          <Loading />
        ) : !currentUser ? (
          <ReloadableError action={refetch} />
        ) : (
          <TeamMemberList
            currentUserId={currentUser.id}
            companyName={currentUser.currentCompany.name}
            recruiters={currentUser.currentCompany.companyRecruiters}
          />
        )}
      </CardContainer>
    </>
  );
};
