import styled from "@xstyled/styled-components";
import { Link, useLocation, useParams } from "react-router-dom";

import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconContainer = styled.div`
  display: inline;
  margin-right: sm;
`;

const ButtonContainer = styled.div`
  margin: xl 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const LineItem = styled(Text)<{ hasMargin?: boolean }>`
  display: flex;
  margin: ${({ hasMargin }) => hasMargin && `lg 0`};
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export const WhyBuyOtta = () => {
  const { companySlug } = useParams<"companySlug">();
  const { state } = useLocation();

  return (
    <JobSlotsModal name="why-otta" redirectOnClose={`/${companySlug}/jobs`}>
      <Text size={2} bold style={{ marginBottom: pxToRem(24) }}>
        3 reasons to add Welcome to the Jungle to your hiring budget
      </Text>

      <LineItem>
        <IconContainer>
          <Icon icon="checkmark" size={2} />
        </IconContainer>
        <div>
          Our candidates are
          <Text bold inline>
            {` twice as likely to progress to interview `}
          </Text>
          compared to other inbound channels.
        </div>
      </LineItem>

      <LineItem hasMargin>
        <IconContainer>
          <Icon icon="checkmark" size={2} />
        </IconContainer>
        <div>
          <Text bold inline>
            {`Pay for what you need. `}
          </Text>
          Flex up and down with your hiring plans.
        </div>
      </LineItem>

      <LineItem>
        <IconContainer>
          <Icon icon="checkmark" size={2} />
        </IconContainer>
        <div>
          <Text bold inline>
            {`Over 2 million candidates. `}
          </Text>
          An application sent every 3 seconds.
        </div>
      </LineItem>

      <ButtonContainer>
        <StyledButton
          level="primary"
          as={Link}
          to={state?.redirect ?? "/pricing"}
        >
          Continue to pricing
        </StyledButton>
        <StyledButton level="destructive" as={Link} to="..">
          Not now
        </StyledButton>
      </ButtonContainer>

      <Text style={{ textAlign: "center" }}>
        <Text bold inline>{`Flexible. `}</Text>
        Cancel or reduce your job slots online at any time.
      </Text>
    </JobSlotsModal>
  );
};
