import { useQuery } from "@apollo/client";
import styled, { css, down } from "@xstyled/styled-components";

import {
  CancelButton,
  PortalLink,
  UpdateCardDetailsLink,
  UpdateSubscriptionButton,
} from "./actions";

import {
  BillingInformationDocument,
  BillingInformationQuery,
} from "@hire/schema";
import { Button, Card, List, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { Link } from "@hire/components/links";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { currencyPrefix } from "@hire/util/currencies";

const LeftCard = styled(Card)`
  text-align: left;
`;

const UpgradeCard = styled(LeftCard)`
  flex-grow: 1;
`;

const LinksCard = styled(LeftCard)`
  margin-bottom: auto;
`;

const EnquireButton = styled(Button)`
  text-decoration: none;
  margin-top: 0.5rem;
`;

const Row = styled.div<{ gap?: string }>`
  gap: ${p => p.gap ?? "1rem"};
  display: flex;

  ${down(
    "tablet",
    css`
      flex-direction: column;
    `
  )}
`;

const SmallCard = styled.div`
  border: ${pxToRem(1)} solid ${palette.grayscale.shade400};
  max-width: ${pxToRem(200)};
  ${down(
    "tablet",
    css`
      max-width: none;
    `
  )}
  border-radius: 4;
  padding: 1rem;
  flex: 1 1 0;
`;

function ProBenefits(): React.ReactElement {
  return (
    <List type="positive">
      <li>Unlimited number of live jobs</li>
      <li>Sourcing functionality to outbound message relevant candidates</li>
      <li>Boosted jobs, shown to candidates first</li>
      <li>Insights, benchmarks and advanced reporting</li>
      <li>Dedicated support from our expert team</li>
    </List>
  );
}

function UpgradeToPro(): React.ReactElement {
  return (
    <UpgradeCard>
      <Spacing size={0}>
        <Text as={"h3"} size={1} bold>
          Upgrade to Pro
        </Text>
        <ProBenefits />
        <EnquireButton
          as={Link}
          level="primary"
          to="/book-a-demo?utm_source=otta&utm_medium=settings&utm_campaign=page_billing&utm_id=hire_app&utm_term=book_a_demo"
          newTab
        >
          Book a Pro demo
        </EnquireButton>
      </Spacing>
    </UpgradeCard>
  );
}

function ProPage(): React.ReactElement {
  return (
    <LeftCard>
      <Spacing size={0}>
        <Text as={"h3"} size={1} bold>
          Current plan - Pro
        </Text>
        <ProBenefits />
      </Spacing>
    </LeftCard>
  );
}

function BasicPage({ name }: { name: string }): React.ReactElement {
  return (
    <Spacing size={0}>
      <LeftCard>
        <Text as={"h3"} size={1} bold>
          Current plan - {name}
        </Text>
      </LeftCard>
      <UpgradeToPro />
    </Spacing>
  );
}

type User = NonNullable<BillingInformationQuery["currentUser"]>;
type Company = NonNullable<User["currentCompany"]>;

type CoreProps = {
  subscription?: NonNullable<Company["activeSubscriptions"]>[0];
  jobSlots: NonNullable<Company["jobSlotData"]>;
};

function Core({ subscription, jobSlots }: CoreProps): React.ReactElement {
  const price = parseFloat(subscription?.price.net ?? "0").toFixed(2);

  return (
    <Spacing size={1}>
      <LeftCard>
        <Spacing size={0}>
          <Text as={"h3"} size={1} bold>
            Current plan - Core
          </Text>
          <Row>
            <SmallCard>
              <Spacing size={-1}>
                <Text>Job slots</Text>
                <Text bold>{jobSlots.total}</Text>
              </Spacing>
            </SmallCard>
            {!!subscription && (
              <SmallCard>
                <Spacing size={-1}>
                  <Text>Price per month</Text>
                  <Text bold>
                    {currencyPrefix(subscription.price.currency)}
                    {price}
                  </Text>
                </Spacing>
              </SmallCard>
            )}
          </Row>
          <Row>
            {subscription ? (
              <>
                <UpdateSubscriptionButton />
                <CancelButton cancelAt={subscription.cancelAt} />
              </>
            ) : (
              <EnquireButton
                as={Link}
                level="secondary"
                to="/quiz/pricing-details"
              >
                Add job slots
              </EnquireButton>
            )}
          </Row>
        </Spacing>
      </LeftCard>
      <Row gap={modularScale(1)}>
        <UpgradeToPro />
        {!!subscription && (
          <LinksCard>
            <Spacing size={0}>
              <UpdateCardDetailsLink />
              <PortalLink>View or change billing information</PortalLink>
              <PortalLink>View invoices</PortalLink>
            </Spacing>
          </LinksCard>
        )}
      </Row>
    </Spacing>
  );
}

export function Billing(): React.ReactElement {
  const { data, loading } = useQuery(BillingInformationDocument);

  if (loading) {
    return <Loading />;
  }

  const company = data?.currentUser?.currentCompany;
  const subscriptions = company?.activeSubscriptions;
  const jobSlots = company?.jobSlotData;

  const pro = company?.isPro ?? false;
  const coreFree = jobSlots && !jobSlots?.paid;
  const corePaid = jobSlots?.paid && !!subscriptions?.length;
  const hasPaidSlots = (jobSlots?.paid || 0) > 0;

  return (
    <Spacing size={1}>
      <Text as="h2" align="left" bold size={2}>
        Billing
      </Text>
      {pro ? (
        <ProPage />
      ) : coreFree ? (
        <Core jobSlots={jobSlots} />
      ) : corePaid ? (
        <Core jobSlots={jobSlots} subscription={subscriptions[0]} />
      ) : (
        <BasicPage name={hasPaidSlots ? "Essential" : "Basic"} />
      )}
    </Spacing>
  );
}
