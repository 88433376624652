import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const WizardStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 4;
  overflow: hidden;
`;

const WizardTitle = styled.div<{
  $expanded?: boolean;
}>`
  display: flex;
  align-items: center;
  user-select: none;
  opacity: ${({ $expanded }) => (!$expanded ? "0.5" : "initial")};
  padding: lg;
  background-color: yellow-500;
`;

const WizardBody = styled.div`
  background: ${palette.brand.white};
  padding: 19;
`;

export function WizardStep({
  title,
  complete,
  expanded,
  children,
}: {
  title: string;
  complete?: boolean;
  expanded?: boolean;
  children: React.ReactNode;
}) {
  return (
    <WizardStepContainer>
      <WizardTitle $expanded={expanded}>
        {complete && (
          <Icon
            icon="checkmark"
            size={1}
            style={{ marginRight: modularScale(-5) }}
          />
        )}
        <Text
          bold={expanded}
          size={1}
          color={
            complete || expanded
              ? palette.brand.black
              : palette.grayscale.shade600
          }
          style={{ textDecoration: complete ? "line-through" : "initial" }}
        >
          {title}
        </Text>
      </WizardTitle>
      {expanded && (
        <WizardBody data-testid="wizard-step-body">{children}</WizardBody>
      )}
    </WizardStepContainer>
  );
}
