import { useNavigate } from "react-router-dom";

import { YesNoComponent } from "./YesNoComponent";

import { Link } from "@hire/components/links";
import { Text } from "@otta/design";

export function AreYouHiringInTheseCategories() {
  const navigate = useNavigate();

  const title = "Are you hiring for roles in the following areas?";
  const infoContent = (
    <Text>
      Only these roles will be posted. If you have questions,{" "}
      <Link
        style={{ fontWeight: "normal" }}
        to="mailto:companies@welcometothejungle.com"
      >
        contact us
      </Link>
      .
    </Text>
  );

  const bullets = [
    "Software Engineering",
    "Data",
    "Product",
    "Design",
    "Sales and Account Management",
    "Marketing",
    "Operations and Strategy",
    "People, HR and Recruitment",
    "Finance, Legal and Compliance",
  ];

  return (
    <YesNoComponent
      step={3}
      title={title}
      infoContent={infoContent}
      bullets={bullets}
      onYesClick={() => {
        navigate("/quiz/pricing-details");
      }}
      onNoClick={() => {
        navigate("/quiz/unsupported");
      }}
    />
  );
}
