import { PersonProgressing } from "../../types";

import { PersonPreview } from "./PersonPreview";
import { ProgressionModal } from "./ProgressionModal";

import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { EmptyList, ItemCardList } from "@hire/components/form/ItemCardList";

interface ProgressionSectionProps {
  people: PersonProgressing[];
  onChange: (updatedPeople: PersonProgressing[]) => void;
}

export const ProgressionSection = ({
  people,
  onChange,
}: ProgressionSectionProps) => {
  return (
    <MoreInfoFieldWrapper
      label="People progressing"
      fieldName="peopleProgressing"
      guidance="We recommend up to 2 people who have been promoted since they joined. This helps candidates see they can progress and grow at your company."
      optional
    >
      <ItemCardList
        data={people}
        name="employee"
        renderForm={props => <ProgressionModal {...props} />}
        renderPreview={person => <PersonPreview {...person} />}
        renderEmpty={() => (
          <EmptyList icon="user" text="Add employees to your profile" />
        )}
        onChange={onChange}
      />
    </MoreInfoFieldWrapper>
  );
};
