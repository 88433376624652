import styled, { keyframes } from "@xstyled/styled-components";
import { useMemo } from "react";

import { BarHeader, GraphHeader } from "./graph-helpers";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";

const GraphContainer = styled.div`
  background-color: white;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: 4;
  padding: lg;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(267)};
  height: 100%;
`;

const Graph = styled.div`
  width: 100%;
  border-bottom: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  border-left: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  margin-bottom: 50;
  height: ${pxToRem(160)};
  display: flex;
  justify-content: space-between;
  padding: 0 40;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    padding: 0 92;
  }
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
`;

const barAnimation = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: var(--barHeight);
  }
`;

const Bar = styled.div`
  width: ${pxToRem(64)};
  height: var(--barHeight);
  background-color: yellow-500;
  border-radius: 2 2 0 0;
  display: flex;
  justify-content: center;
  position: relative;
  animation: ${barAnimation} 0.4s;
`;

const barValueAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BarValue = styled(Text).attrs({ bold: true })`
  padding: 5;
  opacity: 0;
  animation: ${barValueAnimation} 0.2s 0.4s forwards;
`;

type BarDetails = {
  title: string;
  value: number;
};

export function BarGraph({
  bars,
  graphTitle,
}: {
  bars: BarDetails[];
  graphTitle: string;
}) {
  const maxDataPoint = useMemo(
    () => Math.max(...bars.map(bar => bar.value)),
    [bars]
  );

  const barHeight = (value: number) => {
    if (value === 0) return 0;

    return (value / maxDataPoint) * 100 >= 15
      ? (value / maxDataPoint) * 100
      : 15;
  };

  return (
    <GraphContainer data-testid="bar-graph">
      <GraphHeader bold>{graphTitle}</GraphHeader>

      <Graph>
        {bars.map(bar => (
          <Column key={bar.title}>
            <Bar
              style={
                {
                  "--barHeight": `${barHeight(bar.value)}%`,
                } as React.CSSProperties
              }
            >
              {!!bar.value && <BarValue>{bar.value}</BarValue>}
              <BarHeader bold>{bar.title}</BarHeader>
            </Bar>
          </Column>
        ))}
      </Graph>
    </GraphContainer>
  );
}
