import { Link as RouterLink } from "react-router-dom";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { modularScale } from "@otta/design-tokens";

export function ArchivedCompany(): React.ReactElement {
  return (
    <Middle maxWidth={600} style={{ padding: modularScale() }}>
      <Spacing size={-1}>
        <Text as="h2" bold align="center" size={3}>
          Your company has been removed from Welcome to the Jungle!
        </Text>
        <Text align="center">
          It wasn&apos;t meant to be. Get in touch at{" "}
          <Link to="mailto:companies@welcometothejungle.com">
            companies@welcometothejungle.com
          </Link>{" "}
          for more information.
        </Text>
        <Spacing>
          <Button level="primary" as={RouterLink} to="/logout">
            Logout
          </Button>
        </Spacing>
      </Spacing>
    </Middle>
  );
}
