import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { transformIn, transformOut } from "./transformers";
import { JobFormFields } from "./types";
import {
  formatOptions,
  showTechnologiesUsedByJob,
  validationSchema,
} from "./util";
import * as Section from "./NewSections";
import { Preview } from "./Preview";

import { handleMutationError } from "@hire/errors";
import {
  CompanyDetailsDocument,
  CompanyWorkflowStatus,
  JobCategorizationDataDocument,
  JobFragment,
  ProfileType,
  UpdateViewSalaryPermissionDocument,
  UpsertCompanyJobDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import {
  Button,
  Card,
  ErrorContainer,
  ErrorText,
  Spacing,
  Text,
} from "@otta/design";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";

const StickyBottomBar = styled.div`
  position: fixed;
  inset: auto 0 0 0;
  width: 100%;
  background-color: white;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: md;
  align-items: center;
  gap: lg;
`;

const PageError = styled(ErrorContainer)`
  justify-content: center;
  margin: md;
`;

const StyledCard = styled(Card)`
  padding: xl;
  margin-bottom: 7.5rem;
`;

const SplitPage = styled.div`
  flex-direction: row;
  position: relative;
  display: flex;
  gap: 2rem;
`;

const PreviewPane = styled.div`
  background-color: beige-200;
  position: relative;
  display: none;

  ${up(
    "desktop",
    css`
      display: block;
    `
  )}
`;

const PreviewPaneInner = styled.div`
  top: 20;
  position: sticky;
`;

export const JobEditForm = ({
  companyJob,
  onSubmitRedirect,
}: {
  companyJob?: JobFragment | null;
  onSubmitRedirect?: string | undefined;
}) => {
  const navigate = useNavigate();
  const { companySlug } = useParams();

  const { variant } = useExperiment(Experiment.NewJobWizard);
  const { data, loading } = useQuery(JobCategorizationDataDocument);
  const { data: companyData } = useQuery(CompanyDetailsDocument);
  const companyWorkflowStatus =
    companyData?.currentUser?.currentCompany?.workflowStatus;

  const showCompletedCompanyFeatures =
    companyWorkflowStatus &&
    (companyWorkflowStatus === CompanyWorkflowStatus.Completed ||
      companyWorkflowStatus === CompanyWorkflowStatus.Changed);

  const showEBContent =
    companyData?.currentUser?.currentCompany?.profileTypeName ===
    ProfileType.Enhanced;

  const [upsertJobMutation] = useMutation(UpsertCompanyJobDocument, {
    onError: handleMutationError,
  });
  const [updateSalaryPermissions] = useMutation(
    UpdateViewSalaryPermissionDocument,
    {
      onError: handleMutationError,
    }
  );

  const isDraftJob =
    !companyJob || companyJob?.workflowStatus === "RECRUITER_DRAFT";

  const initialValues = useMemo(() => transformIn(companyJob), [companyJob]);

  const handleSubmit = useCallback(
    (data: JobFormFields) => {
      const formattedData = transformOut(data);

      return upsertJobMutation({
        variables: {
          input: formattedData,
          id: companyJob?.externalId ?? null,
          isPreview: data.isPreview ?? false,
        },
        onCompleted: async mutationData => {
          const upsertedJobId = mutationData.upsertCompanyJob?.externalId;
          // The only time we will not navigate will be if there is an error
          if (!upsertedJobId) {
            return;
          }

          if (data.salaryVisibleToIds.length) {
            await updateSalaryPermissions({
              variables: {
                userIds: data.salaryVisibleToIds,
                jobId: upsertedJobId,
              },
            });
          }

          if (data.isPreview) {
            window.open(
              `${import.meta.env.VITE_SEARCH_APP_HOST}/jobs/${upsertedJobId}`,
              "_blank"
            );
            navigate(`/${companySlug}/jobs/${upsertedJobId}/edit`);
          } else if (onSubmitRedirect) {
            navigate(onSubmitRedirect);
          } else {
            navigate(`/${companySlug}/jobs/${upsertedJobId}`);
          }
        },
      });
    },
    [
      companyJob?.externalId,
      companySlug,
      navigate,
      onSubmitRedirect,
      updateSalaryPermissions,
      upsertJobMutation,
    ]
  );

  if (loading || !data) {
    return <Loading />;
  }

  const {
    jobFunctionOptions,
    technologiesUsedOptions,
    languageRequirementOptions,
  } = formatOptions(data);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={data => {
        return handleSubmit(data);
      }}
    >
      {form => (
        <SplitPage>
          <StyledCard>
            <form onSubmit={form.handleSubmit}>
              <Spacing size={6}>
                <Section.Titles />
                <Section.Compensation companyJob={companyJob} />
                <Section.Functions
                  jobFunctionOptions={jobFunctionOptions}
                  companyJob={companyJob}
                />
                {showEBContent && (
                  <Section.Team
                    teamOptions={
                      companyData?.currentUser?.currentCompany?.teams.map(
                        team => ({
                          value: team.id,
                          label: team.name,
                        })
                      ) || []
                    }
                    companyJob={companyJob}
                  />
                )}
                <Section.Experience />
                {showTechnologiesUsedByJob(form.values.jobFunction) && (
                  <Section.Technologies
                    technologiesUsedOptions={technologiesUsedOptions}
                  />
                )}
                <Section.Location />
                <Section.WhoYouAre
                  languageRequirementOptions={languageRequirementOptions}
                />
                <Section.JobInvolves />
                <Section.ApplicationProcess />
              </Spacing>
              <StickyBottomBar>
                {Object.keys(form.errors).length > 0 &&
                  form.submitCount > 0 && (
                    <PageError>
                      <ErrorText>Enter missing information</ErrorText>
                    </PageError>
                  )}
                <SubmitButtonWrapper>
                  <Button
                    type="submit"
                    level="primary"
                    disabled={form.isSubmitting}
                  >
                    Publish {companyJob ? "changes" : "job"}
                  </Button>
                  {isDraftJob && showCompletedCompanyFeatures && (
                    <Button
                      type="button"
                      level="secondary"
                      disabled={form.isSubmitting}
                      onClick={() => {
                        form.setFieldValue("isPreview", true, false);
                        form.submitForm();
                      }}
                    >
                      {form.isSubmitting ? "Saving..." : "Save and preview"}
                    </Button>
                  )}
                </SubmitButtonWrapper>
              </StickyBottomBar>
            </form>
          </StyledCard>
          {variant === "variant" && (
            <PreviewPane>
              <PreviewPaneInner>
                <Spacing size={3}>
                  <Spacing size={0}>
                    <Text bold as={"h2"} size={1}>
                      Preview job
                    </Text>
                  </Spacing>
                  <Preview />
                </Spacing>
              </PreviewPaneInner>
            </PreviewPane>
          )}
        </SplitPage>
      )}
    </Formik>
  );
};
