import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useCookie } from "react-use";

import { MarketingConsent } from "./MarketingConsent";
import { TermsConditionsConsent } from "./TermsAndConditionsConsent";

import {
  Button,
  Card,
  ErrorText,
  Heading,
  InputField,
  PasswordField,
  Spacing,
  Text,
} from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { workEmailRegex } from "@hire/validation/strings";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CompaniesWithPreApprovalDocument,
  CompaniesWithPreApprovalQuery,
} from "@hire/schema";
import { Link } from "@hire/components/links";

const companyFromEmail = (
  data: CompaniesWithPreApprovalQuery | undefined,
  email: string
) => {
  const domain = (email.split("@").pop() ?? "").toLowerCase();
  return companyFromDomain(data, domain);
};

const companyFromDomain = (
  data: CompaniesWithPreApprovalQuery | undefined,
  domain: string
) => {
  return data?.companies.find(
    c => c.websiteUrl?.replace("https://", "")?.toLowerCase() === domain
  );
};

const validationSchema = (data: CompaniesWithPreApprovalQuery | undefined) =>
  Yup.object().shape({
    companyName: Yup.string().required("This field is required"),
    websiteUrl: Yup.string()
      .required()
      .matches(
        /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%\\+.~#()?&//=]*)/i,
        "Enter a valid URL"
      )
      .test(
        "not-existing-company-domain",
        d => {
          const company = companyFromDomain(data, d.value);

          return (
            <>
              This company is already on Welcome to the Jungle. Sign in{" "}
              <Link
                to={`/signup/${company?.urlSafeName}`}
                data-testid="existing-signup-link"
              >
                here
              </Link>
              .
            </>
          );
        },
        domain => {
          const company = companyFromDomain(data, domain);
          if (company) {
            return false;
          }
          return true;
        }
      ),
    currentTitle: Yup.string().required("This field is required"),
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("This field is required")
      .matches(workEmailRegex, "Use your work email")
      .test(
        "not-existing-company-email",
        d => {
          const company = companyFromEmail(data, d.value);
          return (
            <>
              This company is already on Welcome to the Jungle. Sign in{" "}
              <Link
                to={`/signup/${company?.urlSafeName}`}
                data-testid="existing-signup-link"
              >
                here
              </Link>
              .
            </>
          );
        },
        email => {
          const company = companyFromEmail(data, email);
          if (company) {
            return false;
          }
          return true;
        }
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("This field is required"),
    marketingConsent: Yup.boolean().required("This field is required"),
    termsConditionsConsent: Yup.boolean()
      .isTrue("You must accept the terms and conditions")
      .required("This field is required"),
  });

const StyledCard = styled(Card)<{ backgroundColor: string | undefined }>`
  margin: 0 auto;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || palette.brand.white};
`;

export function Onboarding({
  onSuccess,
  title = "Request a Profile",
  companyEmail,
  companyName,
  websiteUrl,
  backgroundColor,
}: {
  onSuccess?: () => void;
  title?: string;
  companyEmail?: string;
  companyName?: string;
  websiteUrl?: string;
  backgroundColor?: string;
}) {
  const apolloClient = useApolloClient();

  const { data } = useQuery(CompaniesWithPreApprovalDocument);

  const [submitError, setSubmitError] = useState<string>();

  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  const form = useFormik({
    initialValues: {
      companyName: companyName ?? "",
      websiteUrl: websiteUrl ?? "",
      currentTitle: "",
      firstName: "",
      lastName: "",
      email: companyEmail || "",
      password: "",
      marketingConsent: null,
      termsConditionsConsent: false,
    },
    validationSchema: validationSchema(data),
    async onSubmit(values) {
      setSubmitError(undefined);

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/auth/signup/company`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(csrf ? { "X-CSRF-Token": csrf } : {}),
            },
            body: JSON.stringify(values),
            credentials: "include",
          }
        );

        if (response.status === 409) {
          return setSubmitError("CONFLICT");
        }

        if (response.status !== 201) {
          throw new Error("Unexpected response");
        }
        if (!onSuccess) {
          window.open(
            "https://share.hsforms.com/1D_7K7wlkS0SL2VIVTCF7_g5gosb",
            "_blank"
          );
        }

        if (onSuccess && response.status === 201) {
          pushAnalyticsEvent({
            eventName: "company-recruiter-sign-up",
            method: "quiz",
          });
          onSuccess();
        }

        await apolloClient.resetStore();
      } catch (e) {
        setSubmitError("UNKNOWN");
      }
    },
  });

  const hasCompanyDetails = companyName && websiteUrl;

  return (
    <StyledCard backgroundColor={backgroundColor}>
      <Spacing size={2}>
        <Heading size={4}>{title}</Heading>
        <form onSubmit={form.handleSubmit}>
          <Spacing size={2}>
            {!hasCompanyDetails && (
              <Spacing size={-1}>
                <Text size={1}>Company details</Text>
                <InputField
                  disabled={form.isSubmitting}
                  label="Company name"
                  name="companyName"
                  value={form.values.companyName}
                  onChange={form.handleChange("companyName")}
                  onBlur={form.handleBlur("companyName")}
                  error={
                    form.touched.companyName
                      ? form.errors.companyName
                      : undefined
                  }
                />
                <InputField
                  disabled={form.isSubmitting}
                  label="Company website"
                  name="websiteUrl"
                  value={form.values.websiteUrl}
                  onChange={form.handleChange("websiteUrl")}
                  onBlur={form.handleBlur("websiteUrl")}
                  error={
                    form.touched.websiteUrl ? form.errors.websiteUrl : undefined
                  }
                />
              </Spacing>
            )}
            <Spacing size={-1}>
              {!hasCompanyDetails && <Text size={1}>Personal details</Text>}
              <InputField
                disabled={form.isSubmitting}
                label="First name"
                name="firstName"
                autoComplete="given-name"
                value={form.values.firstName}
                onChange={form.handleChange("firstName")}
                onBlur={form.handleBlur("firstName")}
                error={
                  form.touched.firstName ? form.errors.firstName : undefined
                }
              />
              <InputField
                disabled={form.isSubmitting}
                label="Last name"
                name="lastName"
                autoComplete="family-name"
                value={form.values.lastName}
                onChange={form.handleChange("lastName")}
                onBlur={form.handleBlur("lastName")}
                error={form.touched.lastName ? form.errors.lastName : undefined}
              />
              <InputField
                disabled={form.isSubmitting}
                label="Current job title"
                name="currentTitle"
                value={form.values.currentTitle}
                onChange={form.handleChange("currentTitle")}
                onBlur={form.handleBlur("currentTitle")}
                error={
                  form.touched.currentTitle
                    ? form.errors.currentTitle
                    : undefined
                }
              />
              <InputField
                disabled={form.isSubmitting}
                label="Work email"
                name="email"
                type="email"
                autoComplete="email"
                value={form.values.email}
                onChange={form.handleChange("email")}
                onBlur={form.handleBlur("email")}
                error={form.touched.email ? form.errors.email : undefined}
              />
              <PasswordField
                disabled={form.isSubmitting}
                label="Password"
                name="password"
                autoComplete="new-password"
                value={form.values.password}
                onChange={form.handleChange("password")}
                onBlur={form.handleBlur("password")}
                requirements={[
                  {
                    text: "Enter at least 8 characters",
                    validate: input => (input ? input.length >= 8 : false),
                  },
                ]}
                error={form.touched.password && !!form.errors.password}
              />
            </Spacing>
            <Spacing size={3}>
              <MarketingConsent
                name="marketingConsent"
                value={form.values.marketingConsent}
                disabled={form.isSubmitting}
                onChange={form.handleChange}
                error={
                  form.touched.marketingConsent
                    ? form.errors.marketingConsent
                    : undefined
                }
              />
              <TermsConditionsConsent
                name="termsConditionsConsent"
                checked={form.values.termsConditionsConsent}
                onChange={v => form.setFieldValue("termsConditionsConsent", v)}
                disabled={form.isSubmitting}
                error={
                  form.touched.termsConditionsConsent
                    ? form.errors.termsConditionsConsent
                    : undefined
                }
              />
            </Spacing>
            {submitError && (
              <ErrorText>
                {submitError === "CONFLICT" ? (
                  <>
                    This email is already used, try another or{" "}
                    <Link to="/login" style={{ display: "inline" }}>
                      sign in
                    </Link>
                    .
                  </>
                ) : submitError === "SSO_ENFORCED" ? (
                  "Your company enforces SSO, sign up with SSO instead."
                ) : (
                  "Something went wrong. Try again."
                )}
              </ErrorText>
            )}
            {hasCompanyDetails ? (
              <Button
                disabled={form.isSubmitting}
                type="submit"
                level="primary"
                style={{ minWidth: pxToRem(300) }}
              >
                {form.isSubmitting
                  ? "Creating account..."
                  : "Continue to payment"}
              </Button>
            ) : (
              <Button
                disabled={form.isSubmitting}
                type="submit"
                level="primary"
                style={{ float: "right" }}
              >
                {form.isSubmitting ? "Creating..." : "Create account"}
              </Button>
            )}
          </Spacing>
        </form>
      </Spacing>
    </StyledCard>
  );
}
