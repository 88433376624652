import { useMutation } from "@apollo/client";
import { Droppable } from "@hello-pangea/dnd";
import styled, { css, up } from "@xstyled/styled-components";

import { useJobInternalId } from "../../hooks/useJobInternalId";
import { NonNullDisableableStage } from "../../types";
import { Checkbox } from "../Checkbox";

import { DeleteStage } from "./DeleteStage";
import { EmptyCard } from "./EmptyCard";
import { PipelineItem } from "./PipelineItem";
import { StageName } from "./StageName";

import { handleMutationError } from "@hire/errors";
import {
  DeletePipelineStageDocument,
  GetJobPipelineDocument,
  StageType,
} from "@hire/schema";
import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Card } from "@otta/design";

const StageColumn = styled(Card)`
  background: ${palette.grayscale.shade50};
  padding: lg sm;
  flex-shrink: 0;
  height: 70vh;
  overflow: hidden;
  width: ${pxToRem(220)};

  ${up(
    "tablet",
    css`
      height: 75vh;
    `
  )}
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20;
  padding-top: 5;
`;

const DroppableArea = styled.div`
  height: calc(70vh - ${pxToRem(62)});
  overflow: auto;
  overflow: overlay;

  ${up(
    "tablet",
    css`
      height: 68vh;
    `
  )}

  > :last-child {
    margin-bottom: sm;
  }

  ::-webkit-scrollbar {
    display: none;
    width: 4px;
  }

  &:hover {
    ::-webkit-scrollbar {
      display: block;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${palette.grayscale.shade400};
    border-radius: ${pxToRem(borderRadius)};
  }
`;

const ActionIcons = styled.div`
  display: flex;
  align-items: center;
`;

interface StageProps {
  stage: NonNullDisableableStage;
  jobId: string;
  selectedItems: string[];
  toggleItemSelection: (itemIds: string[]) => void;
  disabled?: boolean;
}

export const Stage = ({
  stage,
  jobId: jobExternalId,
  selectedItems,
  toggleItemSelection,
  disabled = false,
}: StageProps): React.ReactElement => {
  const { jobInternalId } = useJobInternalId(jobExternalId);
  const items = stage.candidatePipelineItems ?? [];
  const isCustomStage = stage.stageType === StageType.Custom;
  const isArchivedStage = stage.stageType === StageType.Archived;
  const [deleteStageMutation] = useMutation(DeletePipelineStageDocument, {
    refetchQueries: [
      {
        query: GetJobPipelineDocument,
        variables: { jobId: jobExternalId, workExperienceLimit: 1 },
        fetchPolicy: "network-only",
      },
    ],
    onError: handleMutationError,
  });

  const handleDelete = () => {
    deleteStageMutation({
      variables: { jobId: jobInternalId, stageId: stage.id },
    });
  };

  const bulkMessageItems = items.filter(
    item =>
      !!item.candidate &&
      !(item.messageRequest && item.messageRequest.accepted !== true)
  );

  return (
    <StageColumn>
      <HeadingWrapper>
        <StageName
          stage={stage}
          jobExternalId={jobExternalId}
          jobInternalId={jobInternalId}
        />
        <ActionIcons>
          {bulkMessageItems.length > 0 && !isArchivedStage && (
            <Checkbox
              show
              checked={bulkMessageItems.every(item =>
                selectedItems.includes(item.id)
              )}
              handleChange={() => {
                toggleItemSelection(bulkMessageItems.map(item => item.id));
              }}
              data-analytics-id="select-all-items-in-stage-checkbox"
            />
          )}

          {isCustomStage && (
            <DeleteStage
              stageName={stage.name}
              handleDelete={handleDelete}
              isEmpty={items.length < 1}
            />
          )}
        </ActionIcons>
      </HeadingWrapper>
      <div>
        <Droppable droppableId={stage.id} isDropDisabled={disabled}>
          {provided => {
            return (
              <DroppableArea
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {items.length === 0 ? (
                  <EmptyCard type={stage.stageType} />
                ) : (
                  items.map((item, index) => (
                    <PipelineItem
                      key={item.id}
                      index={index}
                      candidateItem={item}
                      stageType={stage.stageType}
                      stageName={stage.name}
                      selectedItems={selectedItems}
                      toggleItemSelection={toggleItemSelection}
                    />
                  ))
                )}
                {provided.placeholder}
              </DroppableArea>
            );
          }}
        </Droppable>
      </div>
    </StageColumn>
  );
};
