import styled from "@xstyled/styled-components";

import { TabLink } from "@hire/components/links";
import { JobFeature } from "@hire/schema";
import { modularScale } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { Tooltip } from "@otta/design";

const DisabledTab = styled.div`
  font-size: ${modularScale(0)};
  color: gray-400;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 5px solid transparent;
  padding-bottom: 6;
  cursor: pointer;
  &:hover {
    border-color: gray-200;
    cursor: not-allowed;
  }
`;

const TabContent = styled.div`
  display: flex;
  gap: 5;
`;

const TabTitles: Partial<Record<JobFeature, string>> = {
  [JobFeature.Analytics]: "Analytics",
  [JobFeature.Broadcast]: "Broadcast",
  [JobFeature.Edit]: "Edit",
  [JobFeature.Inbox]: "Messages",
  [JobFeature.Pipeline]: "Pipeline",
  [JobFeature.Sourcing]: "Sourcing",
};

const TabIcons: Partial<Record<JobFeature, OttaIconsId>> = {
  [JobFeature.Analytics]: "chart-up",
  [JobFeature.Broadcast]: "broadcast",
  [JobFeature.Edit]: "settings",
  [JobFeature.Inbox]: "response-rate",
  [JobFeature.Pipeline]: "users",
  [JobFeature.Sourcing]: "sourcing",
};

export const Tab = ({
  granted,
  feature,
  message,
  to,
}: {
  granted: boolean | undefined;
  feature: JobFeature;
  message: string | undefined | null;
  to: string;
}) => {
  const disabled = message === "Company not active";
  const upsell = message === "upsell";
  const icon = TabIcons[feature];
  if ((granted && feature) || (upsell && feature))
    return (
      <TabLink to={to} end>
        <TabContent>
          <Icon icon={icon === undefined ? "circle-info" : icon} size={1} />
          {TabTitles[feature]}
        </TabContent>
      </TabLink>
    );
  if (disabled && feature)
    return (
      <DisabledTab>
        <Tooltip
          content={
            message === "Company not active"
              ? "This feature will be activated once you're company profile is created."
              : "Feature not currently available."
          }
        >
          <TabContent>
            <Icon icon={icon === undefined ? "circle-info" : icon} size={1} />
            {TabTitles[feature]}
          </TabContent>
        </Tooltip>
      </DisabledTab>
    );
  else {
    return null;
  }
};
