import { FormikErrors, useFormik } from "formik";
import { useCallback, useMemo } from "react";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { transformIn, transformOut } from "./transformers";
import { FormFields } from "./types";

import {
  CompanyDiversityFragment,
  CompanyInput,
  ImageCategory,
} from "@hire/schema";
import { Bullets } from "@hire/components/form/Bullets";
import { ImageUploadField } from "@hire/components/form/ImageUploadField";
import { modularScale } from "@otta/design-tokens";
import { Button, InputField, Spacing, Text, Tipbox } from "@otta/design";
import { mustNotContainEmail } from "@hire/validation/strings";

const FormHeader = styled(Text)`
  font-weight: 600;
  font-size: ${modularScale(1)};
  text-align: left;
  margin-right: sm;
`;

const DiversityRep = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 400px;
`;

const LeftAlignedTipbox = styled(Tipbox)`
  text-align: left;

  & > ul {
    margin-top: sm;
    margin-left: lg;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OptionalText = styled(Text)`
  font-style: italic;
  color: gray-600;
`;

const validationSchema = Yup.object().shape({
  diversityAuthorFullName: Yup.string().optional(),
  diversityAuthorTitle: Yup.string().optional(),
  diversityAuthorProfileImagePath: Yup.string()
    .optional()
    .label("DEI representative photo"),
  diversityInitiatives: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().optional(),
      value: Yup.string()
        .test(mustNotContainEmail)
        .optional()
        .label("Initiative"),
    })
  ),
});

export const CompanyDiversityForm = ({
  data,
  handleSave,
}: {
  data: CompanyDiversityFragment;
  handleSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const onSubmit = useCallback(
    (values: FormFields) => {
      return handleSave(transformOut(values));
    },
    [handleSave]
  );

  const form = useFormik<FormFields>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={2}>
        <Spacing size={-2}>
          <FormHeader size={1} bold align="left">
            DEI information
          </FormHeader>
          <LeftAlignedTipbox level="information">
            <Text bold>
              We recommend you use this space to show candidates:
            </Text>
            <ul>
              <li>Why DEI is important to your company</li>
              <li>
                Your approach to creating a diverse and inclusive organisation
              </li>
              <li>Any specific initiatives or policies you have</li>
              <li>Links to other content you have written on the topic</li>
            </ul>
          </LeftAlignedTipbox>
        </Spacing>

        <Bullets
          fieldName="diversityInitiatives"
          values={form.values.diversityInitiatives}
          onChange={v => form.setFieldValue("diversityInitiatives", v)}
          onBlur={form.handleBlur("diversityInitiatives")}
          placeholders={[
            "We write job description requirements as minimally as possible to encourage candidates who may exclude themselves to apply, and the DEI lead works with hiring managers to minimise bias in the hiring process",
          ]}
          nounSingular="diversity initiative"
          errors={
            form.touched.diversityInitiatives &&
            // Formik types all errors as strings even for nested values
            (
              (form.errors.diversityInitiatives as FormikErrors<
                FormFields["diversityInitiatives"]
              >) ?? []
            ).map(e => e && e.value)
          }
        />
        <TitleContainer>
          <FormHeader>DEI representative</FormHeader>
          <OptionalText>optional</OptionalText>
        </TitleContainer>
        <DiversityRep>
          <Spacing size={2}>
            <InputField
              value={form.values.diversityAuthorFullName}
              name="diversity-author-full-name"
              label="Full name"
              onChange={form.handleChange("diversityAuthorFullName")}
              error={
                form.touched.diversityAuthorFullName
                  ? form.errors.diversityAuthorFullName
                  : undefined
              }
            />
            <InputField
              value={form.values.diversityAuthorTitle}
              name="diversity-author-title"
              label="Job title"
              onChange={form.handleChange("diversityAuthorTitle")}
              error={
                form.touched.diversityAuthorTitle
                  ? form.errors.diversityAuthorTitle
                  : undefined
              }
            />
            <Spacing size={-5}>
              <Text bold>Photo</Text>
              <ImageUploadField
                id="photo"
                nounSingular="a photo of your DEI representative"
                nounPlural="photos"
                fileCountMax={1}
                value={
                  form.values.diversityAuthorProfileImagePath
                    ? [{ path: form.values.diversityAuthorProfileImagePath }]
                    : []
                }
                onChange={e =>
                  form.setFieldValue(
                    "diversityAuthorProfileImagePath",
                    e.length ? e[0].path : undefined
                  )
                }
                category={ImageCategory.Photo}
                error={
                  form.touched.diversityAuthorProfileImagePath
                    ? form.errors.diversityAuthorProfileImagePath
                    : undefined
                }
              />
            </Spacing>
          </Spacing>
        </DiversityRep>

        <Button type="submit" level="primary" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </Spacing>
    </form>
  );
};
